import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import { Link, useTranslation } from '../../utils/i18n';
import styles from './header-logo.module.scss';

interface IProps {}

const HeaderLogo: FunctionComponent<IProps> = memo(({}) => {
  const { t } = useTranslation();
  const [pageName, setPageName] = useState('');
  useEffect(() => {
    const { pathname } = window.location;
    if (/music\/?$/.test(pathname)) {
      setPageName(` | ${t('common.header.music')}`);
    } else if (/review\/?$/.test(pathname)) {
      setPageName(` | ${t('common.header.review')}`);
    }
  }, []);

  return (
    <div className={styles.logo}>
      <Link to='/'>
        <div className='d-flex align-items-center'>
          <img src={logo} />
          <h2 className='pl-3 m-0'>BEAT PANDA{pageName}</h2>
        </div>
      </Link>
    </div>
  );
});

export default HeaderLogo;
