import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { Locale } from '../../models/locale';
import { useStore } from '../../store/store';
import { langNames, languages } from '../../utils/i18n';
import styles from './header-lang-selector.module.scss';

interface IProps {
  basePath: string;
}

// @ts-ignore
const ToggleButton = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=''
    className={styles.langSelectorToggle}
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className='fa fa-globe-asia' />
  </a>
));

const HeaderLangSelector: FunctionComponent<IProps> = observer(({ basePath }) => {
  const lang = useStore<Locale>((s) => s.vm.lang);
  const menuItems = languages.map((language) => ({
    text: langNames[language],
    lang: language,
    active: language === lang,
  }));
  const switchLanguage = useCallback((e) => {
    const appendLang = /\/?(music|article)\/.+/.test(basePath);
    const to = appendLang ? `${basePath}-${e}` : basePath;
    const toWithoutQuery = to.split('?')[0];
    window.location.href = `${window.location.origin}/${e}${toWithoutQuery}/`;
  }, []);

  return (
    <Dropdown className={styles.langSelector} alignRight>
      <DropdownToggle as={ToggleButton} id='lang-selector' />
      <Dropdown.Menu className={styles.langSelectorMenu}>
        {menuItems.map((item) => (
          <Dropdown.Item
            key={item.lang}
            eventKey={item.lang}
            active={item.active}
            onSelect={switchLanguage}
          >
            {item.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default HeaderLangSelector;
