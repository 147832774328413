import React, { FunctionComponent, memo } from 'react';
import Helmet from 'react-helmet';
import { Locale } from '../../models/locale';

interface IProps {
  lang: Locale;
  title: string;
  description: string;
  canonicalUrl?: string;
}

const SeoMetaTags: FunctionComponent<IProps> = memo(
  ({ lang, title, description, canonicalUrl }) => {
    return (
      <Helmet title={title} htmlAttributes={{ lang }}>
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        {description && <meta name='description' content={description} />}
        {canonicalUrl && <link rel='canonical' href={`${canonicalUrl}/`} />}
      </Helmet>
    );
  },
);

export default SeoMetaTags;
