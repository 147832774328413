import React, { FunctionComponent, memo } from 'react';
import { useTranslation } from '../../utils/i18n';
import styles from './footer.module.scss';

const Footer: FunctionComponent = memo(() => {
  const year = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className={styles.socialIcons}>
              <ul>
                <li>
                  <a
                    title={t('common.footer.youtube_title')}
                    href='https://www.youtube.com/channel/UCIHfYtj-MsVPAKTDLH3SprQ'
                    target='_blank'
                    className='youtube-play'
                  >
                    <i className='fab fa-youtube' />
                  </a>
                </li>
                <li>
                  <a
                    title={t('common.footer.ins_title')}
                    href='https://www.instagram.com/beatpandamusic/'
                    target='_blank'
                    className='instagram'
                  >
                    <i className='fab fa-instagram' />
                  </a>
                </li>
                <li>
                  <a
                    title={t('common.footer.twitter_title')}
                    href='https://twitter.com/beatpandamusic'
                    target='_blank'
                    className='twitter'
                  >
                    <i className='fab fa-twitter' />
                  </a>
                </li>
                <li>
                  <a
                    title={t('common.footer.facebook_title')}
                    href='https://www.facebook.com/BeatPandaMusic/'
                    target='_blank'
                    className='facebook'
                  >
                    <i className='fab fa-facebook' />
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.copyright}>
              Copyright © {year} by Beat Panda. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
