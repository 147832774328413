import React, { FunctionComponent, memo } from 'react';
import Helmet from 'react-helmet';
import { languages } from '../../utils/i18n';

interface IProps {
  url: string;
  title: string;
  description?: string;
  image?: string;
  video?: string;
  locale?: string;
}

const OpenGraphTags: FunctionComponent<IProps> = memo(
  ({ title, url, image, video, description, locale }) => {
    const localeAlters = languages.filter((lang) => lang !== locale);

    return (
      <Helmet>
        <meta property='og:title' content={title} />
        <meta property='og:type' content='website' />
        <meta property='og:url' content={`${url}/`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@BeatPandaMusic' />
        <meta name='twitter:title' content={title} />
        {image && <meta property='og:image' content={image} />}
        {image && <meta name='twitter:image' content={image} />}
        {video && <meta property='og:video' content={video} />}
        {video && <meta name='twitter:player' content={video} />}
        {video && <meta name='twitter:player:width' content='1024' />}
        {video && <meta name='twitter:player:height' content='768' />}
        {description && <meta property='og:description' content={description} />}
        {description && <meta name='twitter:description' content={description} />}
        {locale && <meta property='og:locale' content={locale} />}
        {locale &&
          localeAlters.map((l) => <meta key={l} property='og:locale:alternate' content={l} />)}
      </Helmet>
    );
  },
);

export default OpenGraphTags;
