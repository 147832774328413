import React, { FunctionComponent, memo } from 'react';
import { useStore } from '../../store/store';
import { Link, useTranslation } from '../../utils/i18n';
import styles from './header-nav-small.module.scss';

interface IProps {}

const HeaderNavSmall: FunctionComponent<IProps> = memo(({}) => {
  const { t } = useTranslation();
  const vm = useStore((s) => s.vm);
  const onClick = () => {
    vm.setSmallNavOpen(false);
  };
  return (
    <div className={styles.smallNav}>
      <ul>
        <li onClick={onClick}>
          <Link to='/'>
            <span>{t('common.header.home')}</span>
          </Link>
        </li>
        <li onClick={onClick}>
          <Link to='/review/'>
            <span>{t('common.header.review')}</span>
          </Link>
        </li>
        <li onClick={onClick}>
          <Link to='/music/'>
            <span>{t('common.header.music')}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
});

export default HeaderNavSmall;
