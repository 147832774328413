import React, { FunctionComponent, memo } from 'react';
import Helmet from 'react-helmet';
import { buildCanonicalUrl } from '../../utils/define';
import { languages } from '../../utils/i18n';

interface IProps {
  path: string;
  basePath: string;
}

const MultiLangTags: FunctionComponent<IProps> = memo(({ path, basePath }) => {
  const links = languages.map((lang) => {
    const baseUrl = path === basePath ? basePath : `${basePath}-${lang}`;

    return {
      lang,
      link: `${buildCanonicalUrl(baseUrl, lang)}/`,
    };
  });

  return (
    <Helmet>
      {links.map((link) => (
        <link key={link.link} rel='alternate' hrefLang={link.lang} href={link.link} />
      ))}
      <link rel='alternate' hrefLang='x-default' href={`${buildCanonicalUrl(path, 'en')}/`} />
    </Helmet>
  );
});

export default MultiLangTags;
