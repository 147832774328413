import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { Locale } from '../../models/locale';
import { useStore } from '../../store/store';
import Footer from '../footer/footer';
import Header from '../header/header';
import './layout.scss';

interface IProps {
  lang: Locale;
  basePath: string;
  children: any;
}

const Layout: FunctionComponent<IProps> = observer(({ lang, basePath, children }) => {
  const vm = useStore((s) => s.vm);
  vm.setLang(lang);

  return (
    <>
      <Header basePath={basePath} />
      <main>
        <div id='wrapper'>{children}</div>
      </main>
      <Footer />
    </>
  );
});

export default Layout;
