export const siteUrl = 'https://www.beatpanda.io';

export const buildCanonicalUrl = (path: string, lang?: string): string => {
  const langPrefix = lang ? `/${lang}` : '';

  if (path) {
    if (path[0] === '/') {
      return `${siteUrl}${langPrefix}${path}`;
    }
    return `${siteUrl}${langPrefix}/${path}`;
  }

  return `${siteUrl}${langPrefix}`;
};

export const buildArticleDatePath = (date: Date): string =>
  `/article/${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
