import { changeLocale, Link, useIntl } from 'gatsby-plugin-intl';
import { Locale } from '../models/locale';

const languages = Object.keys(Locale).map((key) => Locale[key]);
const langNames = {
  [Locale.EN]: 'English',
  [Locale.TW]: '繁體中文',
};
const useTranslation = () => {
  const intl = useIntl();
  const t = (token: string, values?: object): string =>
    intl.formatMessage({ id: token }, values || {});
  return { t, lang: intl.locale };
};

export { useTranslation, Link, languages, langNames, changeLocale };
