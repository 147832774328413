import React, { FunctionComponent, memo } from 'react';
import { useStore } from '../../store/store';
import { Link, useTranslation } from '../../utils/i18n';
import styles from './header-nav.module.scss';
interface IProps {}

const HeaderNav: FunctionComponent<IProps> = memo(({}) => {
  const { t } = useTranslation();
  const vm = useStore((s) => s.vm);
  const navs = [
    { text: t('common.header.home'), link: '/' },
    { text: t('common.header.review'), link: '/review/' },
    { text: t('common.header.music'), link: '/music/' },
    // { text: t('common.header.shop'), link: '/shop' },
  ];
  return (
    <nav className={styles.headerNav}>
      <ul className='d-none d-sm-none d-md-none d-lg-block'>
        {navs.map((nav) => (
          <li key={nav.link}>
            <Link to={nav.link}>
              <span>{nav.text}</span>
            </Link>
          </li>
        ))}
      </ul>
      <a
        href='#'
        className={`d-block d-sm-block d-md-block d-lg-none ${styles.smallNavToggle}`}
        onClick={() => vm.setSmallNavOpen(!vm.smallNavOpen)}
      >
        <i className='fas fa-bars' />
      </a>
    </nav>
  );
});

export default HeaderNav;
