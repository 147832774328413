import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../store/store';
import HeaderLangSelector from '../header-lang-selector/header-lang-selector';
import HeaderLogo from '../header-logo/header-logo';
import HeaderNavSmall from '../header-nav-small/header-nav-small';
import HeaderNav from '../header-nav/header-nav';

interface IProps {
  basePath: string;
}

const Header: FunctionComponent<IProps> = observer(({ basePath }) => {
  const isSmallNavOpen = useStore<boolean>((s) => s.vm.smallNavOpen);

  return (
    <header>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 d-flex justify-content-between align-items-center'>
            <HeaderLogo />
            <div className='d-flex justify-content-start align-items-center'>
              <HeaderNav />
              <HeaderLangSelector basePath={basePath} />
              {/*<HeaderCart />*/}
            </div>
          </div>
        </div>
      </div>
      {isSmallNavOpen && <HeaderNavSmall />}
    </header>
  );
});

export default Header;
